// Credit for NanoId to https://github.com/ai/nanoid
export let nanoid = (t = 21) => {
  let e = "",
    r = crypto.getRandomValues(new Uint8Array(t));
  for (; t--; ) {
    let n = 63 & r[t];
    e +=
      n < 36
        ? n.toString(36)
        : n < 62
        ? (n - 26).toString(36).toUpperCase()
        : n < 63
        ? "_"
        : "-";
  }
  return e;
};

export const DB = {
  nanoid,
  CP_GAMES: {
    put: function (env, k, v) {
      return env.CP_GAMES.put(k, v);
    },
    get: function (env, k) {
      return env.CP_GAMES.get(k);
    },
  },
};

export const API = {
  getGame: async function (id) {
    let response = await fetch(`/api/game/${id}`, {
      method: "GET",
      headers: { "Content-Type": "applications/json" },
    });
    return await response.json();
  },
  updateGame: async function (id, state) {
    let response = await fetch(`/api/game/${id}`, {
      method: "PUT",
      headers: { "Content-Type": "applications/json" },
      body: JSON.stringify(state),
    });

    if (!response.ok) {
      let r = await response.json();
      throw new Error(r.message);
    }

    return await response.json();
  },
  createGame: async function (data) {
    let response = await fetch(`/api/game/new`, {
      method: "POST",
      headers: { "Content-Type": "applications/json" },
      body: JSON.stringify(data),
    });

    console.log("Created", response);

    return await response.json();
  },
};

export const GAME_STATUSES = {
  UNSTARTED: "UNSTARTED",
  STARTED: "STARTED",
  FINISHED: "FINISHED",
  LOADING: "LOADING",
};

export const NEW_GAME_STATE = {
  words: [],
  cheese: false,
  pants: false,
};
