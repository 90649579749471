import React, { useState, useEffect, useRef } from "react";
import { GAME_STATUSES } from "./data-util";

export default function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

export const useCheckForWin = (gameState, setGameStatus) => {
  useEffect(() => {
    let endMarks = [".", "!", "?"];
    if (!gameState.words.length) {
      return setGameStatus(GAME_STATUSES.STARTED);
    }

    if (!gameState.cheese || !gameState.pants) {
      return setGameStatus(GAME_STATUSES.STARTED);
    }

    let lastWord = gameState.words[gameState.words.length - 1].w;
    let lastChar = lastWord.split("")[lastWord.length - 1];

    // Last word is solely punctuation
    if (endMarks.includes(lastWord)) {
      return setGameStatus(GAME_STATUSES.FINISHED);
    }

    // Last word includes a punctuation
    if (endMarks.includes(lastChar)) {
      return setGameStatus(GAME_STATUSES.FINISHED);
    }

    setGameStatus(GAME_STATUSES.STARTED);
  }, [gameState.words]);
};

export const useNotify = () => {
  const img = "/cheese-pants-carving-transparent.png";
  const text = "Notifications are enabled!";

  // Let's check if the browser supports notifications
  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support system notifications");
    } else if (Notification.permission === "granted") {
      new Notification("Game Updates", {
        body: text,
        icon: img,
      });
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          new Notification("🧀👖 Game Updates Enabled", {
            body: text,
            icon: img,
          });
        }
      });
    }
  }, []);

  function sendNotification({
    title = "Game Updates",
    message = "🧀👖 Game Has Been Updated!",
  }) {
    console.log("Trying to send a desktop notify", title, message);
    const img = "/cheese-pants-carving-transparent.png";
    new Notification(title, { body: message, icon: img });
  }

  return sendNotification;
};
