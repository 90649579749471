import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  FormHelpText,
  Tooltip,
  AutoGrid,
  Spinner,
  Badge,
} from "mainsail-ui";
import useLocalStorage, { useCheckForWin, useNotify, useAbly } from "./hooks";
import { API, GAME_STATUSES } from "./data-util";
import { configureAbly, useChannel, usePresence } from "@ably-labs/react-hooks";
const ABLY_CLIENT_KEY =
  "LrEd3A.IzhGlA:wHzcJT_9o0zImGa7zmFILGIM8LDtal02Pcl4kHw2C2c";
const DEBUG = false;

const DEFAULT_GAME = {
  words: [],
  cheese: false,
  pants: false,
};

export default function App() {
  const [yourName, setYourName] = useLocalStorage("name", "Dr. Queso");
  const [gameState, setGameState] = useState(DEFAULT_GAME);
  const [word, setWord] = useState("");
  const [incomingWord, setIncomingWord] = useState(null);
  const [gameStatus, setGameStatus] = useState(GAME_STATUSES.UNSTARTED);
  const [copyButtonText, setCopyButtonText] = useState("Copy Url");
  const notify = useNotify();
  let path = window.location.pathname;
  let pathSegs = path.split("/");
  let gameId = pathSegs[2];

  // Check for win conditions
  useCheckForWin(gameState, setGameStatus);

  // Set up Realtime
  configureAbly({ key: ABLY_CLIENT_KEY, clientId: yourName });
  const [activeUsers, updateStatus] = usePresence(gameId);
  useChannel(gameId, "word", ({ data }) => {
    console.log(data);
    if (!data) {
      return null;
    }

    if (data && data.n === yourName) {
      return null;
    }

    notify({
      title: "New 🧀👖 Update Received",
      message: `"${data.w}" sent by ${data.n}`,
    });

    setIncomingWord(data);
  });

  // Update Gamestate When Incoming word
  useEffect(() => {
    if (!incomingWord) {
      return null;
    }
    setGameState({
      ...gameState,
      words: [...gameState.words, incomingWord],
    });
  }, [incomingWord]);

  // Load Game State
  useEffect(() => {
    // Rudimentary redirect
    if (pathSegs[1] === "game" && !gameId) {
      window.location.href = `/`;
    }

    try {
      if (gameId) {
        setGameStatus(GAME_STATUSES.LOADING);

        API.getGame(gameId)
          .then((gameSave) => {
            setGameState(gameSave);
          })
          .catch((e) => {
            console.error(e);
            alert(e.message);
          });
      } else if (window.location.pathname === "/") {
        setGameStatus(GAME_STATUSES.UNSTARTED);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const handleWordChange = (e) => {
    setWord(e.target.value);
  };

  const clearInput = () => {
    setWord("");
  };

  const isValidWord = (w) => {
    // Do we need to do any more checks?
    if (w.split(" ").length > 1) {
      alert("Please submit only one word at a time...");
      return false;
    }
    return true;
  };

  const onSubmitWord = () => {
    let currentWord = word.trim();
    if (!isValidWord(currentWord)) {
      return;
    }

    let previousWord = gameState.words[gameState.words.length - 1];

    if (yourName === previousWord.n) {
      return alert("Why not let someone else submit the next word?");
    }

    API.updateGame(gameId, {
      word: currentWord,
      name: yourName,
      current_words: JSON.stringify(gameState.words),
    })
      .then((newState) => {
        setGameState(newState);
      })
      .catch((e) => {
        console.error(e);
        alert(e.message);
      });

    clearInput();
  };

  const onStartGame = () => {
    let currentWord = word.trim();
    if (!isValidWord(currentWord)) {
      return;
    }

    API.createGame({ word: currentWord, name: yourName })
      .then((newState) => {
        window.location.href = `/game/${newState.id}`;
      })
      .catch((e) => {
        console.log(e);
        alert("Failed to create game...");
      });
  };

  const restartGame = () => {
    window.location.href = "/";
  };

  const onCopyUrl = () => {
    navigator.clipboard.writeText(window.location).then(() => {
      setCopyButtonText("Copied!");
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (gameStatus === GAME_STATUSES.STARTED) {
      onSubmitWord();
    } else {
      onStartGame();
    }
  };

  const renderStats = () => {
    let { words } = gameState;
    let stats = [];

    let longestWord = "";
    let longestWordCount = 0;
    let players = [];

    words.forEach((word) => {
      // Add to names list
      players.push(word.n);

      if (word.w.length > longestWordCount) {
        longestWordCount = word.w.length;
        longestWord = `Longest Word: ${word.w} by ${word.n}`;
      }
    });

    // Add stats to iterable

    // Players count
    stats.push({
      color: "pink",
      text: `Players: ${[...new Set(players)].length}`,
    });

    // Word stats
    stats.push({
      color: "violet",
      text: `Total words: ${words.length}`,
    });

    stats.push({
      color: "orange",
      text: longestWord,
    });

    return (
      <div className="mt-4 flex align-center space-x-4">
        <div className="body-text sm">Game Stats:</div>
        {stats.map((stat) => (
          <Badge text={stat.text} color={stat.color} />
        ))}
      </div>
    );
  };

  const renderWord = ({ w }) => {
    if (w.toLowerCase().includes("cheese")) {
      return w.replace(/cheese/i, "🧀");
    }
    if (w.toLowerCase().includes("pants")) {
      return w.replace(/pants/i, "👖");
    }

    return w;
  };

  const { words } = gameState;

  return (
    <AutoGrid
      cols={[12, 12, 12]}
      flow={AutoGrid.flows.row}
      className="w-full bkg-image"
    >
      <AutoGrid.Item colStart={[2, 2, 4]} colEnd={[12, 12, 10]}>
        <section className="flex align-center">
          <h1 className="gradient text-blue-dark align-center">
            Cheese Pants!
          </h1>
        </section>
        {gameStatus === GAME_STATUSES.LOADING && (
          <section className="bg-neutral-6 flex column align-center box-shadow-sticky border-neutral-5 mb-12 rounded p-12">
            <Spinner />
          </section>
        )}

        {words.length > 0 && (
          <>
            <section className="current-words bg-neutral-6 flex column align-center box-shadow-sticky border-neutral-5 mb-12 rounded p-12">
              <h1>
                {words.map((word, idx) => {
                  return (
                    <span key={`${word.w}_${idx}`}>
                      <Tooltip text={`By ${word.n}`}>
                        {idx === 0 ? renderWord(word) : ` ${renderWord(word)}`}
                      </Tooltip>
                    </span>
                  );
                })}
              </h1>
              {activeUsers.length > 0 && (
                <div className="body-text sm p-6 bg-neutral-6 space-x-4">
                  Currently watching:{" "}
                  {activeUsers.map((u) => (
                    <Badge
                      color="green"
                      isRounded
                      hasColorInverted
                      text={u.clientId}
                    />
                  ))}
                </div>
              )}
            </section>
            {gameStatus === GAME_STATUSES.FINISHED && (
              <section className="bg-neutral-6 flex column align-center box-shadow-sticky border-neutral-5 mb-12 rounded p-12">
                <h3 className="m-0">Game Finished!</h3>
                {renderStats()}
                <Button
                  className="mt-12"
                  text="Start a New Game"
                  onClick={restartGame}
                />
              </section>
            )}
          </>
        )}
        {gameStatus !== GAME_STATUSES.FINISHED && (
          <section className="bg-neutral-6 rounded p-20 mb-12 box-shadow-sticky relative mobile-overflow-hide">
            <img
              src="/cheese-pants-carving-transparent.png"
              alt="Cheese Pants Carving"
              className="cheese-carving-image"
            />
            <form onSubmit={onFormSubmit}>
              <FormControl id="word" className="mb-6 w-1/2">
                <FormLabel text={`Who are You?`} />
                <Input
                  name="name"
                  defaultValue={yourName}
                  onBlur={(e) => setYourName(e.target.value)}
                />
                <FormHelpText text="Your name can appear next to the word you added to the sentence (hover the words of the sentence to see them)" />
              </FormControl>

              <FormControl id="word" className="mb-6 w-1/2">
                <FormLabel text={`Type Your Word:`} />
                <Input
                  name="word"
                  value={word}
                  onChange={handleWordChange}
                  autoCapitalize="none"
                />
                <FormHelpText text="Submit a word and send the link to your friend!" />
              </FormControl>

              <div className="flex row">
                {gameStatus === GAME_STATUSES.STARTED && (
                  <Button
                    text="Submit Word"
                    className="mr-10"
                    onClick={onSubmitWord}
                    isDisabled={!word.length}
                    type="submit"
                  />
                )}
                {gameStatus === GAME_STATUSES.UNSTARTED && (
                  <Button
                    text="Start Game"
                    className="mr-10"
                    onClick={onStartGame}
                    isDisabled={!word.length}
                    type="submit"
                  />
                )}
                {gameStatus === GAME_STATUSES.STARTED && (
                  <Button
                    type="button"
                    text={copyButtonText}
                    onClick={onCopyUrl}
                    iconRight="drafts"
                    variant="secondary"
                  />
                )}
              </div>
            </form>
          </section>
        )}

        <section className="bg-neutral-5 rounded p-20 mb-12 box-shadow-sticky">
          <h3 className="my-10">FAQ</h3>
          <p className="mt-10 body-text lg text-blue-dark">How do I play?</p>
          <ul className="body-text">
            <li>Write a word. Add it to the sentence.</li>
            <li>Share the URL with your friends. Have them do the same.</li>
            <li>Rinse and repeat.</li>
            <li>
              The sentence is not finished until "cheese" and "pants" and a
              final punctuation mark have been added to the sentence.
            </li>
            <li>Embrace the crazy.</li>
          </ul>
        </section>

        <div className="flex row space-x-6 my-12 text-blue-light body-text sm">
          <a className="text-blue-light" href="/">
            Start New Game
          </a>
          <span>Copyright ® {new Date().getFullYear()} Joshua Weaver</span>
        </div>
        {DEBUG && (
          <>
            <div className="mt-20 rounded bg-neutral-6 p-12">
              <h3>DEBUG</h3>
              <pre>{JSON.stringify(gameState, null, 2)}</pre>
            </div>
          </>
        )}
      </AutoGrid.Item>
    </AutoGrid>
  );
}
